let isScrolling;

const LazyResponseImages = class {
    constructor() {
    }

    Run(loadOutOfViewport = false) {
        Array.from(document.querySelectorAll("[data-lazyTarget]"))
            .filter(element => {
                if(loadOutOfViewport){
                    return true;
                }

                return element.tagName === "PICTURE"
                    ? this.InView(element.querySelector('img'))
                    : this.InView(element);
            })
            .filter(this.NotLoaded)
            .forEach(this.ProcessElement.bind(this));
    }

    ProcessElement(element) {


        if(element.tagName !== "PICTURE"){
            let height = element.clientHeight ?? element.height;
            let width = element.clientWidth ?? element.width;

            height = element.dataset.autoheight ? 0 : height;
            width = element.dataset.autowidth ? 0 : width;
            
            
            const sizes = this.ProcessDPI(height, width);
            const dimensions = `height=${sizes.height}&width=${sizes.width}`;

            const actualSource = element.dataset.src.replace("{0}", dimensions);

            if (element.tagName === "IMG") {
                element.src = actualSource;
            } else {
                element.style.backgroundImage = 'url(' + actualSource + ')';
            }
        }
        else{
            const img = element.querySelector('img');
            let height = img.clientHeight ?? img.height;
            let width = img.clientWidth ?? img.width;

            height = element.dataset.autoheight ? 0 : height;
            width = element.dataset.autowidth ? 0 : width;
            
            
            const sizes = this.ProcessDPI(height, width);
            const dimensions = `height=${sizes.height}&width=${sizes.width}`;

            Array.from(element.querySelectorAll('source'))
                .forEach(source => {
                    const actualSource = source.dataset.src.replace("{0}", dimensions);
                    source.srcset = actualSource;
                });

            Array.from(element.querySelectorAll('img'))
                .forEach(source => {
                    const actualSource = source.dataset.src.replace("{0}", dimensions);
                    source.src = actualSource;
                });
        }

        element.dataset["loaded"] = true;
    }

    NotLoaded(element) {
        return element.dataset["loaded"] === undefined || element.dataset["loaded"] === "false";
    }

    ClearAll(container) {
        container.querySelectorAll("[data-lazyTarget]").forEach(function (element) {
            element.dataset["loaded"] = false;
        });
    }

    InView(element) {
        const windowBottom = document.documentElement.scrollTop + window.outerHeight;
        const elementTop = element.getBoundingClientRect().top + 100;

        return elementTop <= windowBottom;
    }

    ProcessDPI(height, width)
    {
        if(this.isRetina())
        {
            const multiplier = window.devicePixelRatio ? window.devicePixelRatio : 2;
            height = Math.ceil(height * multiplier);
            width = Math.ceil(width * multiplier);
        }

        return {height, width};
    }

    isRetina() {
        return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2));
    }

    addScrollListener(element){
        element.addEventListener("scroll",
            function () {
                window.clearTimeout(isScrolling);

                isScrolling = setTimeout(function () {
                        processor.Run();
                    },
                    100);
            });
    }

};

const processor = new LazyResponseImages();

document.addEventListener("DOMContentLoaded",
    function () {
        processor.Run();
    });

window.onresize = function () {
    processor.ClearAll(document);
    processor.Run();
};
processor.addScrollListener(window);